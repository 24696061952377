import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '0.46',   // PONER TAMBIEN EN Pages/Login.vue
    uuid: '',
    salvoconducto: 'HeladosGelati639',
    //urlserver: 'vps-fbd2675d.vps.ovh.net',
    urlserver: 'pedidos.gelatigelati.com',
    //puertoserver: '8081',
    puertoserver: '8443',
    menuadmin: true,
    usuario: {}

  },
  mutations: {
    modifUuid(state, payload) {
      state.uuid = payload
    },
    modifMenuadmin(state, payload) {
      state.menuadmin = payload
    }
  },
  getters: {
    dameUuid(state) {
      return state.uuid
    },
    dameMenuadmin(state) {
      return state.menuadmin
    },
    verVersion(state) {
      return state.version
    }
  }
})
