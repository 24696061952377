<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Iniciar Sesión</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|min:3"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="text"
                               :error="failed ? 'El usuario es obligatorio': null"
                               :hasSuccess="passed"
                               label="Usuario o Correo Electrónico"
                               name="email"
                               v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:2"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input  type="password"
                               :error="failed ? 'La contraseña es obligatoria': null"
                               :hasSuccess="passed"
                               name="password"
                               label="Contraseña"
                               v-model="password">
                    </fg-input>
                  </ValidationProvider>
                  <!--
                  <fg-input>
                    <l-checkbox v-model="subscribe">
                      Subscribe to newsletter
                    </l-checkbox>
                  </fg-input>-->
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Entrar</button>
                  <br>
                  <!--<div class="forgot">
                    <router-link to="/register" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>-->
                </div>
                <div slot="footer">
                  v. {{vers}}
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>

  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import axios from "axios";
import crypt from "crypto-js";
import AES from "crypto-js/aes";

extend("email", email);
extend("required", required);
extend("min", min);

  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data() {
      return {
        email: "",
        password: "",
        subscribe: true,
        vers: ""
      };
    },
    created() {
      this.vers = this.$store.state.version
      // COMPROBAMOS SI HAY NUEVA VERSION EN EL CLIENTE PARA FORZAR UN REFRESCO
      if (localStorage.getItem('pedidos-version')) {
        if (this.$store.state.version != localStorage.getItem('pedidos-version')) {
          var version_old = localStorage.getItem('pedidos-version')
          localStorage.setItem('pedidos-version', this.$store.state.version)
          console.log('Se ha actualizado a la versión: '+this.$store.state.version+' desde la: '+version_old)
          window.location.reload(true)
        } else {
          console.log('Versión: '+this.$store.state.version)
        }
      } else {
        localStorage.setItem('pedidos-version', this.$store.state.version)
        console.log('Versión: '+this.$store.state.version)
      }
    },
    mounted() {

    },
    methods: {
      submit() {
        var mijson = {
          funcion: "ficha",
          tabla: "usuarios",
          campos: "id, usuario, perfil, cliente, email, serie",
          where: "(usuario='"+this.email+"' OR usuario='"+this.email+"') AND (password='"+this.password+"')"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.$store.state.usuario=resp[0]
          console.log("Usuario: ",this.$store.state.usuario)
          if (this.$store.state.usuario.perfil==1) {
            this.$router.push('/admin/cliente')
          }
          if (this.$store.state.usuario.perfil==2) {
            this.$router.push('/admin/encargado')
          }
          if (this.$store.state.usuario.perfil==3) {
            this.$router.push('/admin/administrador')
          }

        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })

      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
